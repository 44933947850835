import i18n from "../../service/translations/translationService";
import tooltipStyles from "../../styles/tooltip.module.css";
import StandardButton from "../UI/buttons/StandardButton";

export default function LanguageOptionButton({
  optionName,
  language
}: {
  optionName: string;
  language: string;
}) {
  const updateLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
  };

  return (
    <StandardButton
      onClick={() => updateLanguage(language)}
      classes={`
      ${tooltipStyles.tooltipOption}
      ${
        i18n.language === language
          ? `${tooltipStyles.tooltipOptionSelected}`
          : ""
      }`}
    >
      {optionName}
    </StandardButton>
  );
}
