import { BagstageUserDetails } from "../../pages/HomePage";
import tooltipStyles from "../../styles/tooltip.module.css";
import profileStyles from "../../styles/userProfile.module.css";
import Tooltip from "../UI/Tooltip";

export default function UserProfile({
  bagstageUser
}: {
  bagstageUser: BagstageUserDetails;
}) {
  return (
    <Tooltip
      icon={
        <div className={profileStyles.userProfileChip}>
          {bagstageUser.userInitials}
        </div>
      }
      classes={`${tooltipStyles.tooltipContent} ${profileStyles.userProfileContainer} `}
    >
      <p className={profileStyles.username}>{bagstageUser.userName}</p>
      <p className={profileStyles.useremail}>{bagstageUser.userEmail}</p>
    </Tooltip>
  );
}
