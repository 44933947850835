import { KeycloakUserTokenType } from "../types/user.types";

class UserService {
  static getUserInitials = (userData: KeycloakUserTokenType) => {
    // destructure to avoid multiple repetition.
    const { given_name, family_name, bagstage_user_name, preferred_username } =
      userData;

    /**
     * If given_name and family_name are present, return first letter of each
     * else, use bagstage_user_name
     * else user preferred_username
     *  */
    if (given_name && family_name) {
      return given_name[0].toUpperCase() + family_name[0].toUpperCase();
    } else if (bagstage_user_name) {
      const words = bagstage_user_name.trim().split(" ");
      if (words.length >= 2) {
        return words[0][0].toUpperCase() + words[1][0].toUpperCase();
      } else if (words.length === 1) {
        return bagstage_user_name[0].toUpperCase();
      }
    } else if (preferred_username) {
      return preferred_username[0].toUpperCase();
    }
    return "";
  };

  static getUsername = (userData: KeycloakUserTokenType) => {
    // destructure to avoid multiple repetition.
    const { given_name, family_name, bagstage_user_name } = userData;
    if (given_name && family_name) {
      return given_name + " " + family_name;
    } else if (bagstage_user_name) {
      return bagstage_user_name;
    } else {
      return "";
    }
  };
}

export default UserService;
