import { ReactComponent as LanguageIcon } from "../../assets/icons/bagstage_language.svg";
import i18n from "../../service/translations/translationService";
import homePageStyles from "../../styles/homePage.module.css";
import tooltipStyles from "../../styles/tooltip.module.css";
import { languages, languagesFullForm } from "../../utils/commonUtil";
import Tooltip from "../UI/Tooltip";
import LanguageOptionButton from "./LanguageOptionButton";

export default function LanguageSelectionTooltip() {
  return (
    <Tooltip
      icon={
        <div className={homePageStyles.languageSection}>
          <LanguageIcon />
          <p className={homePageStyles.selectedLanguage}>
            {i18n.language.toUpperCase()}
          </p>
        </div>
      }
      classes={tooltipStyles.tooltipContent}
    >
      <LanguageOptionButton
        optionName={languagesFullForm.danish}
        language={languages.da}
      />
      <LanguageOptionButton
        optionName={languagesFullForm.english}
        language={languages.en}
      />
    </Tooltip>
  );
}
